





































import Vue from 'vue'
import Faqs from '@/components/faqs/Faqs.vue'
import CallToAction from '@/components/common/CallToAction.vue'

export default Vue.extend({
  components: {
    CallToAction,
    Faqs
  },
  computed: {
    isLoading () {
      return this.$store.state.faqs.loading
    },
    faqs () {
      return this.$store.state.faqs.data
    }
  },
  created () {
    this.$store.dispatch('faqs/fetchSellersFaqs')
  }
})













import Vue from 'vue'

export default Vue.extend({
  props: {
    faqItems: {
      type: Array,
      default () { return [] }
    },
    faqPanelTitle: {
      type: String,
      default: 'Questions Fréquentes'
    },
    faqPanelSubTitle: {
      type: String,
      default: 'Trouvez la réponse à votre question'
    }
  }
})
